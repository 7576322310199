import { useState, useEffect } from 'react';
const useLoading = () => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState<string>();

	useEffect(() => {
		if(!loading) clearMessage();
	}, [loading]);

	const openLoading = (message?: string) => {
		setLoading(true);
		setMessage(message);
	};
	const closeLoading = () => setLoading(false);
	const isOpen = () => loading;

	const getMessage = () => message;
	const clearMessage = () => setMessage(undefined);

	return {
		openLoading,
		closeLoading,
		isOpen,

		getMessage,
		clearMessage
	};
};

export default useLoading;