import { useState, useEffect } from 'react';
import { env } from 'env';

const LOCAL_STORAGE_KEY = 'APP-THEME';
const DEFAULT_THEME: keyof typeof AvailableThemes = env.DEFAULT_THEME || 'light-mode';

enum AvailableThemes{
	'dark-mode',
	'light-mode'
}
const useTheme = () => {
	const getInitialThemeValue = () => {
		const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_KEY) || DEFAULT_THEME;
		return localStorageTheme === 'light-mode' || localStorageTheme === 'dark-mode' ? localStorageTheme : DEFAULT_THEME;
	};

	const [theme, setTheme] = useState<keyof typeof AvailableThemes>(getInitialThemeValue());

	useEffect(() => {
		changeLocalStorageTheme();
		alterBodyTheme();
	}, [theme]);

	const alterBodyTheme = () => {
		const body = document.querySelector('body');

		if(!body) return;

		body.classList.remove('light-mode', 'dark-mode');
		body.classList.add(theme);
	};
	const changeTheme = () => {
		const newTheme = theme === 'light-mode' ? 'dark-mode' : 'light-mode';
		setTheme(newTheme);
	};	
	const getThemeAlias = (): 'dark'|'light' => theme.split('-')[0] as 'dark' | 'light';

	const changeLocalStorageTheme = () => localStorage.setItem(LOCAL_STORAGE_KEY, theme);

	const detectBrowserTheme = () => {
		const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

		return darkThemeMq.matches ? 'dark-mode' : 'light-mode';
	};

	return {
		detectBrowserTheme,
		changeTheme,
		getThemeAlias
	};
};

export default useTheme;