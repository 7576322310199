import { createContext, useContext } from 'react';
import { useLoading as useLoadingHook } from 'hooks';

interface ILoadingProps{
    openLoading: (message?: string) => void
    closeLoading: () => void
    isOpen: () => boolean

    getMessage: () => string | undefined
    clearMessage: () => void
}

const LoadingContext = createContext({} as ILoadingProps);
const LoadingProvider = ({ children }: React.PropsWithChildren<{}>) => {
	return <LoadingContext.Provider value={useLoadingHook()}>{children}</LoadingContext.Provider>;
};
const useLoading = () => useContext(LoadingContext);

export default useLoading;
export { LoadingProvider };