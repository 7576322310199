import { useLoading } from 'contexts';
const Loading = () => {
	const { isOpen, getMessage } = useLoading();
	
	return isOpen() ?
		<div id="loading-container">
			<div className="loader"></div>
			{getMessage() && <h2 className="message">{getMessage()}</h2>}
		</div> : <></>;
};

export default Loading;