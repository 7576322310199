// import { useState } from 'react';
import { Tooltip, Stack } from '@mui/material';
// import { Link } from 'react-router-dom';

import FlexManageLogo from 'assets/img/logo/FlexManage.svg';

import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
const LadingPage = () => {
	// const [hamburgerMenu, setHamburguerMenu] = useState(false);
	// const [visible, setVisible] = useState(false);

	// const toggleMenu = () => setHamburguerMenu(state => !state);
	// const scrollTo = (id: string, extraSpacing=0) => {
	// 	const element = document.getElementById(id);
	// 	if (!element) return;

	// 	const top = element.offsetTop + extraSpacing;
	// 	window.scrollTo({ top, behavior: 'smooth' });
	// };
	// const toggleVisible = () => {
	// 	const scrolled = document.documentElement.scrollTop;
	// 	setVisible(scrolled > 300);
	// };
	// window.addEventListener('scroll', toggleVisible);
	
	return (
		<main id="landing-page">
			<header>
				<nav className="navbar">
					<a className="logo not-effect" href="#">
						<img src={FlexManageLogo} alt="FlexManage logo" />
					</a>
					{/* <ul className={`nav-menu ${hamburgerMenu && 'active'}`}>
						<li className="nav-item">
							<Link to="/register" target="_blank" onClick={toggleMenu}>Assinar</Link>
						</li>
						<li className="nav-item">
							<Link to="/login" target="_blank" onClick={toggleMenu}>Entrar</Link>
						</li>
					</ul>
					<div className={`hamburger ${hamburgerMenu && 'active'}`} onClick={toggleMenu}>
						<span className="bar"></span>
						<span className="bar"></span>
						<span className="bar"></span>
					</div> */}
				</nav>
			</header>
			{/* <div className="toolbar"></div> */}


			<div>
				<h1 className='color-primary'>Em Breve Novidades</h1>
				<p className="section--article--subTitle">
					Não deixe de acompanhar!
				</p>
			</div>
			{/* <section>
				<div className="section--wrapper">
					<div className="section--container">
						<div className="section--article">
							<h1 className="section--article--title">
								Simplifique a gestão da sua empresa.
							</h1>
							<p className="section--article--subTitle">
								Gerenciar sua empresa nunca foi tão fácil. <br />Simplifique seu dia a dia com o FlexManage.
							</p>
							<Stack
								display="flex"
								gap={2}
								sx={{
									justifyContent: {
										sm: 'center',
										md: 'start',
									},
									flexDirection: {
										xs: 'column',
										md: 'row'
									}
								}}
							>
								<Button
									type="button"
									variant="outlined"
									color="secondary"
									className="not-effect"
									sx={{
										minWidth: 250
									}}
									onClick={() => scrollTo('about', -75)}
								>
									Veja mais +
								</Button>

								<Button
									href="/login"
									target="_blank"
									type="button"
									variant="contained"
									color="primary"
									className="not-effect"
									sx={{
										minWidth: 250
									}}
								>
									Acessar
								</Button>
							</Stack>
						</div>

						<div className="section--galery">
							<img src="/assets/img/landingPage/section001.jpeg" alt="" />
						</div>
					</div>
				</div>

				<div id="about" className="section--wrapper">
					<div className="section--container">
						<div className="section--article">
							<h1 className="section--article--title">
								Gestão completa para o seu negócio.
							</h1>
							<p className="section--article--subTitle color-white">
								Com o FlexManage, você pode cadastrar todos os clientes e
								animais do seu estabelecimento em uma única plataforma e
								acessá-los facilmente, com todos os históricos e informações
								relevantes em um só lugar.
							</p>
						</div>

						<div className="section--galery">
							<img src="/assets/img/landingPage/section002.jpeg" alt="" />
						</div>
					</div>
				</div>

				<div className="section--wrapper">
					<div className="section--container">
						<div className="section--article">
							<h1 className="section--article--title">
								Controle financeiro simplificado.
							</h1>
							<p className="section--article--subTitle">
								Gerencie todas as transações financeiras de sua empresa,
								desde pagamentos até recebimentos, de forma fácil e intuitiva
								com o FlexManage. Tenha um controle completo das suas finanças na palma de suas mãos.
							</p>
						</div>

						<div className="section--galery">
							<img src="/assets/img/landingPage/section003.jpeg" alt="" />
						</div>
					</div>
				</div>

				<div className="section--wrapper">
					<div className="section--article text-align-center">
						<h1 className="section--article--title color-white m-0">
							Curtiu a plataforma?
						</h1>
						<p className="section--article--subTitle color-medium mt-0 mb-4">
							Faça o cadastro e experimente nosso sistema por 7 dias de forma gratuita
						</p>
						<Button
							href="/register"
							target="_blank"
							type="button"
							variant="contained"
							color="primary"
							sx={{
								paddingLeft: {
									xs: '25px!important',
									sm: '50px!important'
								},
								paddingRight: {
									xs: '25px!important',
									sm: '50px!important'
								}
							}}
							className="px-5 not-effect"
						>
							Experimente por 7 dias
						</Button>
					</div>
				</div>
			</section> */}

			<footer className="text-align-center">
				<div className="content mx-auto">
					<h3>Segue a gente lá nas redes sociais</h3>

					<Stack display="flex" flexDirection="row" gap={10} alignItems="center" justifyContent="center">
						<Tooltip title="@FlexManage">
							<a href="#" target="_blank" className="not-effect cursor-pointer color-primary">
								<Instagram fontSize="large" />
							</a>
						</Tooltip>

						<Tooltip title="/FlexManage">
							<a href="#" target="_blank" className="not-effect cursor-pointer color-primary">
								<Facebook fontSize="large" />
							</a>
						</Tooltip>

						<Tooltip title="/FlexManage">
							<a href="#" target="_blank" className="not-effect cursor-pointer color-primary">
								<LinkedIn fontSize="large" />
							</a>
						</Tooltip>
					</Stack>
				</div>
				<div className="copyright">
					<p className="m-0 py-2 color-medium">FlexManage | Todos os direitos reservados | 2023 ©</p>
				</div>
			</footer>

			{/* <IconButton 
				className={`backToTop ${visible && 'show'}`} 
				color="primary"
				onClick={() => scrollTo('landing-page')}
			>
				<KeyboardArrowUp fontSize="large" />
			</IconButton> */}
		</main>
	);
};

export default LadingPage;
