import { useContext, createContext } from 'react';
import { useUserAuthentication as useUserHook } from 'hooks/useUserAuthentication';
import { IUserContextProps } from '@interfaces/IUserAuthenticationProps';

const UserContext = createContext({} as IUserContextProps);
const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const { isLoading, ...props } = useUserHook();

	return (
		<UserContext.Provider value={props}>
			{!isLoading() ? children : <></>}
		</UserContext.Provider>
	);
};

const useUser = () => useContext(UserContext);

export default useUser;
export { UserProvider };