import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();
	
	return (
		<div id="default-page" className="d-flex">
			<section className="m-auto text-align-center">
				<h1 className="mb-4" style={{ fontSize: 50 }}>
					Página não encontrada
				</h1>

				<p style={{ fontSize: 20 }}>
					Desculpe, não conseguimos localizar essa página <br />
					<a
						type="button"
						className="color-secondary cursor-pointer mx-1"
						onClick={() => navigate(-1)}
					>
						Clique aqui
					</a>
					para voltar à aplicação.
				</p>
			</section>
		</div>
	);
};

export default Error;