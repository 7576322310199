import { z } from 'zod';

const nodeEnv = z.enum(['development', 'production']);

// const requiredOnEnv = (env: z.infer<typeof nodeEnv>) => (value: any) => env === process.env.NODE_ENV && !value ? false : true; 

const envSchema = z.object({
	NODE_ENV: nodeEnv.default('development'),

	URL_API_PROTOCOL: z.string().min(1).default('http:'),
	URL_API_HOST: z.string().min(1),
	URL_API_PORT: z.coerce.number().min(1).default(5000),

	DEFAULT_AUTHORIZATION_BASIC_USER: z.string().min(1),
	DEFAULT_AUTHORIZATION_BASIC_PASSWORD: z.string().min(1),

	DEFAULT_TIMEOUT_REQUEST: z.coerce.number().min(1).default(10000),
	DEFAULT_TIME_RESEND_TOKEN: z.coerce.number().min(1).default(60),

	DEFAULT_THEME: z.enum(['dark-mode', 'light-mode']).default('light-mode')
});

const envServer = envSchema.safeParse({
	URL_API_PROTOCOL: process.env.REACT_APP_URL_API_PROTOCOL,
	URL_API_HOST: process.env.REACT_APP_URL_API_HOST,
	URL_API_PORT: process.env.REACT_APP_URL_API_PORT,

	DEFAULT_AUTHORIZATION_BASIC_USER: process.env.REACT_APP_DEFAULT_AUTHORIZATION_BASIC_USER,
	DEFAULT_AUTHORIZATION_BASIC_PASSWORD: process.env.REACT_APP_DEFAULT_AUTHORIZATION_BASIC_PASSWORD,

	DEFAULT_TIMEOUT_REQUEST: process.env.REACT_APP_DEFAULT_TIMEOUT_REQUEST,
	DEFAULT_TIME_RESEND_TOKEN: process.env.REACT_APP_DEFAULT_TIME_RESEND_TOKEN,

	DEFAULT_THEME: process.env.REACT_APP_DEFAULT_THEME,
});

if (!envServer.success) {
	console.error(envServer.error.issues);
	throw new Error('There is an error with the server environment variables');
	process.exit(1);
}

export const env = envServer.data;