import axios, { AxiosResponse, AxiosError, Method, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { IUserProps } from '@interfaces/IUserAuthenticationProps';

import { env } from 'env';

export type useApiProps<DataProps = undefined> = {
	requestError: boolean
	data: DataProps
	status?: number

	code?: string
	message?: string
}
const useApi = () => {
	const getHeaders = () => {
		const user_data = getUserProps();
		return {
			'content-type': 'application/json',
			...(user_data && {
				accesstoken: `Bearer ${user_data.token}`
			})
		};
	};

	const getUserProps = (): IUserProps | undefined => {
		try{
			const user_data = localStorage.getItem('USER_DATA');
			return user_data ? JSON.parse(user_data) : undefined;
		}catch(err){
			return undefined;
		}
	};

	const getBaseURL = () => {
		if (!env.NODE_ENV || env.NODE_ENV === 'development') {
			return `${env.URL_API_PROTOCOL}//${env.URL_API_HOST}:${env.URL_API_PORT}`;
		} else {
			return `${document.location.protocol}//${document.location.hostname}${env.URL_API_PORT}`;
		}
	};
	const getDefaultAxiosCreateProps = (): AxiosRequestConfig => {
		return {
			baseURL: getBaseURL(),
			timeout: Number(env.DEFAULT_TIMEOUT_REQUEST),
			responseType: 'json',
			headers: getHeaders()
		};
	};
	/**
	 * @params router: API router path
	 * @params method: HTTP Request Method
	 * @params params: Parameters you want to send to the api
	 * @params showMessageError: If true, it will show a message if the request has an error
	 * @params axiosOptions: Options for creating the axios instance (import { CreateAxiosDefaults } from axios)
	 */
	// eslint-disable-next-line
	async function sendRequest<DataProps>(router: string, method: Method, params?: any, showMessageError = false, axiosOptions?: AxiosRequestConfig) { 
		const INSTANCE = axios.create(axiosOptions || getDefaultAxiosCreateProps());

		return await INSTANCE.request<undefined, AxiosResponse<DataProps>, undefined>({
			url: router,
			data: params,
			method: method,
			auth: {
				username: env.DEFAULT_AUTHORIZATION_BASIC_USER || 'USERNAME',
				password: env.DEFAULT_AUTHORIZATION_BASIC_PASSWORD || 'PASSWORD'
			}
		})
			.then(({ data, status }) => ({ status, data, requestError: false }))
			.catch(({ code, message, response }: AxiosError) => {
				return {
					code,
					message,
					data: response?.data as DataProps,
					status: response?.status,
					requestError: true
				};
			})
			.then((response: useApiProps<DataProps>) => {
				if (response.requestError) {
					switch (response.status) {
					case 401:
					case 400:
						try {
							// if (response.data?.message === 'token expirado') {
							// 	toast.error('Sessão expirada. Por favor, faça o login novamente!');
							// 	localStorage.removeItem('USER_DATA');
							// 	window.location.href = '/';
							// }
						} catch (err) {
							console.log(err);
						}
						break;
					case 500:
						// erro no back-end
						break;
					}
					if (showMessageError) {
						try {
							const messageError = `${response.code && `[${response.code}]`}[${response.message}]`;
							toast.error(`Aconteceu um imprevisto durante a execução da requisição: "${messageError}"`);
						} catch (err) {
							console.log(err);
						}
					}
				}

				return response;
			});
	}

	return {
		sendRequest,
		getDefaultAxiosCreateProps,
		getBaseURL,
		getHeaders
	};
};

export default useApi;