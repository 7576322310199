import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import useApi from './useApi';
import { useLoading } from 'contexts';

import { IRequestProps } from '@interfaces/index';

import { 
	IResetPasswordStep1Props, 
	IResetPasswordStep2Props,
	ILoginProps, 
	ILoginTokenVerificationProps,
	AccountUpdatePasswordFormProps
} from '@interfaces/index';
import { IUserProps } from '@interfaces/IUserAuthenticationProps';

const BASE_URL_API = '/api/v1';
export const useUserAuthentication = () => {
	const navigate = useNavigate();
	const { openLoading, closeLoading } = useLoading();
	const { sendRequest } = useApi();

	const [userData, setUserData] = useState<IUserProps>({} as IUserProps);
	const [authLoading, setAuthLoading] = useState(true);

	useEffect(() => {
		try {
			const data = JSON.parse(localStorage.getItem('USER_DATA') || '{}') as IUserProps;
			setUserData(data);
		} catch (err) {
			console.log(err);
		}

		setAuthLoading(false);
	}, []);

	/***************
	* RESET PASSWORD
	***************/ 
	const checkEmailToChangePassword = async (data: IResetPasswordStep1Props) => {
		openLoading();

		const response = await sendRequest<IRequestProps<{ hasAccount: boolean } | undefined>>(`${BASE_URL_API}/checkEmailToChangePassword`, 'POST', { data });
		if (response && !response.requestError) {
			const { STATUS, message, error, data } = response.data;

			closeLoading();
			if (!STATUS) {
				await validationPreLoginRequestResponse(message || error, data);
				return false;
			}

			return true;
		} else {
			if (response.data?.message || response.data?.error) toast.warn(response.data.message || response.data.error);
			else if (response.message) toast.warn(response.message);

			closeLoading();
			return false;
		}
	};

	const changePassword = async (data: IResetPasswordStep1Props & IResetPasswordStep2Props) => {
		openLoading();

		const response = await sendRequest<IRequestProps>(`${BASE_URL_API}/changePassword`, 'PUT', { data });
		if (response && !response.requestError) {
			const { STATUS, message, error } = response.data;

			closeLoading();
			if (STATUS) {
				toast.success(message);
			} else if (!STATUS && (message || error)) toast.warn(message || error);

			return STATUS;
		} else {
			if (response.data?.message || response.data?.error) toast.warn(response.data.message || response.data.error);
			else if (response.message) toast.warn(response.message);

			closeLoading();
			return false;
		}
	};
	const accountUpdatePassword = async (data: AccountUpdatePasswordFormProps) => {
		openLoading();

		const response = await sendRequest<IRequestProps>(`${BASE_URL_API}/accountUpdatePassword`, 'PUT', { data });
		if (response && !response.requestError) {
			const { STATUS, message, error } = response.data;

			closeLoading();
			if (STATUS) {
				toast.success(message);
			} else if (!STATUS && (message || error)) toast.warn(message || error);

			return STATUS;
		} else {
			if (response.data?.message || response.data?.error) toast.warn(response.data.message || response.data.error);
			else if (response.message) toast.warn(response.message);

			closeLoading();
			return false;
		}
	};

	/***************
	* LOGIN 
	***************/ 
	const checkLogin = async (data: ILoginProps) => {
		openLoading();
		const response = await sendRequest<IRequestProps<{ hasAccount: boolean } | { hasPassword: boolean } | undefined>>(`${BASE_URL_API}/checkLogin`, 'POST', { data });
		if (response && !response.requestError) {
			const { STATUS, message, error, data } = response.data;

			closeLoading();
			if (!STATUS) {
				await validationPreLoginRequestResponse(message || error, data);
				return false;
			}
			
			return STATUS;
		} else {
			if (response.data?.message || response.data?.error) toast.warn(response.data.message || response.data.error);
			else if (response.message) toast.warn(response.message);

			closeLoading();
			return false;
		}
	};
	const handleLogin = async ({ EMAIL, TOKEN }: Pick<ILoginProps, 'EMAIL'> & ILoginTokenVerificationProps) => {
		openLoading();
		
		const response = await sendRequest<IRequestProps<IUserProps | { hasAccount: boolean } | { hasPassword: boolean }>>(`${BASE_URL_API}/login`, 'POST', { data: { EMAIL, TOKEN } });
		if (response && !response.requestError) {
			const { STATUS, message, error, data } = response.data;

			closeLoading();
			if (!STATUS) {
				await validationPreLoginRequestResponse(message || error, data as { hasAccount: boolean } | { hasPassword: boolean });
				return;
			}
			
			localStorage.setItem('USER_DATA', JSON.stringify(data));
			setUserData(data as IUserProps);

			navigate('/adm', { replace: true });
		} else {
			if (response.data?.message) toast.warn(response.data?.message);
			else if (response.message) toast.warn(response.message);

			closeLoading();
		}
	};

	/***************
	* LOGOUT
	***************/ 
	const handleLogout = () => {
		setUserData({} as IUserProps);
		localStorage.removeItem('USER_DATA');
		navigate('/');
	};

	/***************
	* AUXILIAR FUNCTIONS
	***************/ 
	const itsMe = (EMAIL: string) => EMAIL === userData?.user.email;
	const isAuthenticated = () => !!(userData && Object.keys(userData).length > 0);
	const isLoading = () => authLoading;
	const getInitialsLetters = () => {
		if(!userData) return '';
		
		const allNames = userData.user.name.trim().split(' ');
		const initials = allNames.reduce((acc, curr, index) => {
			if(index === 0 || index === allNames.length - 1){
				acc = `${acc}${curr.charAt(0).toUpperCase()}`;
			}
			return acc;
		}, '');
		return initials;
	};
	const validationPreLoginRequestResponse = async (message?: string, data?: { hasAccount: boolean } | { hasPassword: boolean }) => {
		if (data && 'hasAccount' in data && !data.hasAccount) {
			userDontHaveAccount(message);
		} else if(data && 'hasPassword' in data && !data.hasPassword){
			userDontHavePassword(message);
		} else if (message) toast.warn(message);
	};
	const userDontHaveAccount = async (message?: string) => {
		const { isConfirmed } = await Swal.fire({
			icon: 'info',
			title: 'Poxa :(',
			html: message,
			customClass: {
				confirmButton: 'bg-info',
				cancelButton: 'bg-error'
			},
			showCancelButton: true,
			reverseButtons: true,
			cancelButtonText: 'Não, obrigado!',
			confirmButtonText: 'Quero sim!'
		});

		isConfirmed && navigate('/register');
	};
	const userDontHavePassword = async (message?: string) => {
		const { isConfirmed } = await Swal.fire({
			icon: 'info',
			title: 'Poxa :(',
			html: message,
			customClass: {
				confirmButton: 'bg-info',
				cancelButton: 'bg-error'
			},
			showCancelButton: true,
			reverseButtons: true,
			cancelButtonText: 'Não, obrigado!',
			confirmButtonText: 'Quero sim!'
		});

		isConfirmed && navigate('/resetPassword');
	};

	return {
		userData,

		checkEmailToChangePassword,
		changePassword,
		accountUpdatePassword,
		
		checkLogin,
		handleLogin,

		handleLogout,

		getInitialsLetters,
		isAuthenticated,
		isLoading,
		itsMe
	};
};