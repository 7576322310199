import * as z from 'zod';

import { cnpjIsValid } from 'utils/validation';

const NAME_SCHEMA = /^[A-Za-z\u00C0-\u00FF ]*(([,.] |[ '-])[A-Za-z][a-z\u00C0-\u00FF ]*)*(\.?)$/;
const PASSWORD_REGEX = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

/*********************************************
* ANYTHING
*********************************************/
export const AVAILABLE_STATUS_SCHEMA = z.enum(['ACTIVE', 'BLOCKED']);
export const PERSON_TYPE = z.enum(['PJ', 'PF']).default('PJ');
export const DEFAULT_DB_SCHEMA = z.object({
	_id: z.string().nonempty().readonly(),
	CREATED_AT: z.string().datetime().nonempty().readonly(),
	UPDATED_AT: z.string().datetime().optional().readonly(),
	STATUS: AVAILABLE_STATUS_SCHEMA.default('BLOCKED').readonly()
});

/*********************************************
* LOGIN
*********************************************/
export const LoginSchema = z.object({
	EMAIL: z.string().email('Insira um e-mail válido').nonempty('Campo obrigatório'),
	PASSWORD: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(PASSWORD_REGEX),
			'A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial'
		)
});
export const LoginTokenVerificationSchema = z.object({
	TOKEN: z.string().nonempty('Campo obrigatório').min(6, 'Mínimo de 6 caracteres').max(6, 'Máximo de 6 caracteres')
});

/*********************************************
* RESET PASSWORD
*********************************************/
export const ResetPasswordStep1Schema = z.object({
	EMAIL: z.string().email('Insira um e-mail válido').nonempty('Campo obrigatório')
});
export const ResetPasswordStep2Schema = z.object({
	TOKEN: z.string().nonempty('Campo obrigatório').min(6, 'Mínimo de 6 caracteres').max(6, 'Máximo de 6 caracteres'),
	PASSWORD: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(PASSWORD_REGEX),
			'A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial'
		),
	CONFIRM_PASSWORD: z.string().nonempty('Campo obrigatório')
}).refine(data => data.PASSWORD === data.CONFIRM_PASSWORD, {
	message: 'As senha não conferem',
	path: ['CONFIRM_PASSWORD']
});

/*********************************************
* REGISTER
*********************************************/
export const RegisterFormSchemaProps = z.object({
	DOCUMENT: z.string().nonempty('Campo obrigatório'),
	CUSTOMER_NAME: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(NAME_SCHEMA),
			'Insira um nome válido'
		),
	USER_NAME: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(NAME_SCHEMA),
			'Insira um nome válido'
		),
	PHONE: z.string().nonempty('Campo obrigatório').min(15, 'Insira um telefone válido').max(15, 'Insira um telefone válido'),
	EMAIL: z.string().email('Insira um e-mail válido').nonempty('Campo obrigatório')
}).refine(data => cnpjIsValid(data.DOCUMENT), {
	message: 'Documento inválido',
	path: ['DOCUMENT']
});

/*********************************************
* PROFILE
*********************************************/
export const RULES_SCHEMA = z.object({
	MODULE_ID: z.string(),
	MODULE_NAME: z.string(),
	CAN_EDIT: z.boolean(),
	CAN_INSERT: z.boolean(),
	CAN_DELETE: z.boolean(),
	STATUS: AVAILABLE_STATUS_SCHEMA,
});
export const ProfileFormStep1Schema = z.object({
	NAME: z.string().nonempty('Campo obrigatório'),
	DESCRIPTION: z.string().nonempty('Campo obrigatório')
});
export const ProfileFormStep2Schema = z.object({
	RULES: RULES_SCHEMA.array()
});
export const ProfileFormSchema = ProfileFormStep1Schema.extend({
	...ProfileFormStep2Schema.shape
});
export const ProfileDBSchema = ProfileFormSchema.extend({
	...DEFAULT_DB_SCHEMA.shape
});

/*********************************************
* USER
*********************************************/
export const UserFormSchema = z.object({
	NAME: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(NAME_SCHEMA),
			'Insira um nome válido'
		),
	PHONE: z.string().nonempty('Campo obrigatório').min(15, 'Insira um telefone válido').max(15, 'Insira um telefone válido'),
	EMAIL: z.string().email('Insira um e-mail válido').nonempty('Campo obrigatório'),
	PROFILE_ID: z.string()
});
export const UserDBSchema = UserFormSchema.extend({
	...DEFAULT_DB_SCHEMA.shape
});
/*********************************************
* MODULE
*********************************************/
export const ModuleSchema = z.object({
	NAME: z.string().nonempty('Campo obrigatório'),
	DESCRIPTION: z.string().nonempty('Campo obrigatório')
});
export const ModuleDBSchema = ModuleSchema.extend({
	...DEFAULT_DB_SCHEMA.shape
});
/*********************************************
* ACCOUNT > UPDATE PASSWORD
*********************************************/
export const AccountUpdatePasswordSchema = z.object({
	CURRENT_PASSWORD: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(PASSWORD_REGEX),
			'A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial'
		),
	PASSWORD: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(PASSWORD_REGEX),
			'A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial'
		),
	CONFIRM_PASSWORD: z.string().nonempty('Campo obrigatório')
}).refine(data => data.CURRENT_PASSWORD !== data.PASSWORD, {
	message: 'A nova senha não pode ser igual a atual',
	path: ['PASSWORD']
}).refine(data => data.PASSWORD === data.CONFIRM_PASSWORD, {
	message: 'As senha não conferem',
	path: ['CONFIRM_PASSWORD']
});
/*********************************************
* ACCOUNT > ACCOUNT INFO
*********************************************/
export const AccountInfoSchema = z.object({
	NAME: z.string().nonempty('Campo obrigatório')
		.regex(
			new RegExp(NAME_SCHEMA),
			'Insira um nome válido'
		),
	PHONE: z.string().nonempty('Campo obrigatório').min(15, 'Insira um telefone válido').max(15, 'Insira um telefone válido'),
	EMAIL: z.string().email('Insira um e-mail válido').nonempty('Campo obrigatório'),
});