import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from 'hooks';

// Reference URL: https://mui.com/pt/material-ui/customization/default-theme/
const Theme = ({ children }: { children: React.ReactNode }) => {
	const { getThemeAlias } = useTheme();

	let theme = createTheme({
		palette: {
			mode: getThemeAlias(),
			primary: {
				main: '#55df7b',
				contrastText: '#fff',

			},
			secondary: {
				main: '#4D3EFC',
				contrastText: '#fff'
			},
			info: {
				main: '#4AA6F7',
				contrastText: '#fff'
			},

			success: {
				main: '#55df7b',
				contrastText: '#fff'
			},
			warning: {
				main: '#F1C40F',
				contrastText: '#fff'
			},
			error: {
				main: '#AD2D2D',
				contrastText: '#fff'
			}
		},
		typography: {
			fontFamily: 'Syne'
		}
	});

	theme = createTheme(theme, {
		components: {
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 14,
						background: 'var(--firstPlanColor)'
					}
				}
			},
			MuiInputAdornment: {
				styleOverrides: {
					root: {
						color: 'red!important'
					}
				}
			},
			MuiDisa: {},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 14,
						textTransform: 'none',
						'&.Mui-disabled': {
							backgroundColor: 'var(--disabledColor)'
						}
					},
					sizeMedium: {
						height: 45,
						fontSize: 16,
						letterSpacing: 2
					}
				},
			},
			MuiDialogTitle: {
				styleOverrides: {
					root: {
						padding: '10px 24px!important'
					}
				}
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: 16,
						// background: '#1F1717'
					}
				}
			},
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: 16,
						background: 'var(--firstPlanColor)'
					}
				}
			},
			MuiListItemButton:{
				styleOverrides: {
					root: {
						':hover': {
							backgroundColor: `${theme.palette.primary.main}7F`,
							textDecoration: 'none'
						},
						'&.menu-active': {
							backgroundColor: `${theme.palette.primary.main}7F`,
							textDecoration: 'none'
						}
					}
				},
				
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	);
};

export default Theme;