import { Route, Routes } from 'react-router-dom';

import LandingPage from 'pages/LandingPage';

import Error from 'pages/Error';

const AppRoutes = () => {
	return(
		<Routes>
			<Route path="/" element={ <LandingPage /> } />
			
			<Route path="*" element={ <Error /> } />
		</Routes>
	);
};

export default AppRoutes;