import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Theme from 'theme';
import AppRoutes from 'routes';

import { LoadingProvider, UserProvider } from 'contexts';
import Loading from 'components/Loading';

const App = () => {
	return (
		<BrowserRouter>
			<ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				pauseOnHover={false}
				closeOnClick
				draggable
				theme="colored"
			/>
			<LoadingProvider>
				<Loading />
				
				<Theme>
					<UserProvider>
						<AppRoutes />
					</UserProvider>
				</Theme>
			</LoadingProvider>
		</BrowserRouter>
	);
};

export default App;