export const cpfIsValid = (cpf: string): boolean => {
	cpf = cpf.replace(/[^\d]+/g, '');

	if (cpf.length !== 11 || /^[0-9]{11}$/.test(cpf.replace(/./g, ''))) return false;

	const isValidDigit = (index: number): boolean => {
		let sum = 0;

		for (let i = 0; i < index; i++) {
			sum += Number(cpf.charAt(i)) * (index + 1 - i);
		}

		const remainder = sum % 11;
		if (remainder < 2) {
			return Number(cpf.charAt(index)) === 0;
		} else {
			return Number(cpf.charAt(index)) === 11 - remainder;
		}
	};

	return isValidDigit(9) && isValidDigit(10);
};

export const cnpjIsValid = (cnpj: string): boolean => {
	cnpj = cnpj.replace(/[^\d]+/g,'');
    
	if (cnpj === '') return false;
	if (cnpj.length !== 14) return false;

	// Elimina CNPJs inválidos conhecidos
	const cnpjInvalidosConhecidos = [
		'00000000000000', '11111111111111', '22222222222222',
		'33333333333333', '44444444444444', '55555555555555',
		'66666666666666', '77777777777777', '88888888888888',
		'99999999999999'
	];

	if (cnpjInvalidosConhecidos.includes(cnpj)) return false;

	// Valida DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	const digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;

	for (let i = tamanho; i >= 1; i--) {
		soma += Number(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2) pos = 9;
	}

	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado !== Number(digitos.charAt(0))) return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;

	for (let i = tamanho; i >= 1; i--) {
		soma += Number(numeros.charAt(tamanho - i)) * pos--;
		if (pos < 2) pos = 9;
	}

	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	return resultado === Number(digitos.charAt(1));
};
